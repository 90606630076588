import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import "./schedule.css"
import moment from 'moment';
import { openOrCloseModal } from '../../../../helper/CreateDispatches';
import { v4 as uuidv4 } from "uuid"
import TimeSetterModal from '../../../../Components/Modals/OrganizerModel/ManageFeis/ScheduleMaker/TimeSetterModal';
import { calculateCompDuration, getDraggableItemClass, getHeadingTd, removeCompFromStage, removeStageHandler, setTimingsWithinCol } from './scheduleHelpers';
import ConfirmDeleteModal from '../../../../Components/Modals/OrganizerModel/ManageFeis/Tabulate/ConfirmDeleteModal';
import { serviceConsumer } from '../../../../network/ServiceConsumer';

const StagesTable = ({ tableData, setTableData, eventId, evokeFilteredComp }) => {
    const [draggedCompetition, setDraggedCompetition] = useState()
    const [deletableIndex, setDeletableIndex] = useState()
    const [deletableName, setDeletableName] = useState("")
    const [deleteableStageId, setDeleteableStageId] = useState("")

    const [isBreakTime, setIsBreakTime] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [columnKeyy, setColumnKey] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const modal = useSelector(state => state.modalReducer)

    const syncScroll = (event, columnIndex) => {
        if (columnIndex !== 0) {
            const sourceElement = event.target;
            const columns = document.querySelectorAll('.scroll-column');

            const sourceScrollTop = sourceElement.scrollTop;
            const sourceScrollHeight = sourceElement.scrollHeight - sourceElement.clientHeight;
            const scrollPercentage = sourceScrollTop / sourceScrollHeight;

            columns.forEach((col) => {
                const colScrollHeight = col.scrollHeight - col.clientHeight;
                if (colScrollHeight > 0) {
                    col.scrollTop = scrollPercentage * colScrollHeight;
                }
            });
        }
    };




    const handleDragEnd = (result) => {
        try {
            const { source, destination } = result;

            // If dropped outside of droppable area or dropped back to the original position, do nothing
            if (!destination || (source?.droppableId === destination?.droppableId && source?.index === destination?.index)) {
                return;
            }

            const sourceColumnKey = source?.droppableId;
            const destinationColumnKey = destination?.droppableId;
            const item = tableData[sourceColumnKey][source?.index];

            //only for competition details for TimeSetter Modal
            setDraggedCompetition(item)

            if (destination.index !== 0 && sourceColumnKey === "Events_Events" && item.code !== "Break Time" && sourceColumnKey !== destinationColumnKey) {
                const prevItem = tableData[destinationColumnKey][destination?.index - 1];
                const startTime = moment(prevItem.endTime, 'hh:mm A');
                let endTime;
                if (item.code === "Break Time") {
                    endTime = moment(prevItem.endTime, 'hh:mm A').add(30, 'minutes');
                } else {
                    const duration = calculateCompDuration(item)
                    endTime = moment(prevItem.endTime, 'hh:mm A').add(duration, 'minutes');
                }

                // Format the new times back to "HH:MM AM/PM" format
                item.startTime = startTime.format('hh:mm A');
                item.endTime = endTime.format('hh:mm A');
            }

            //break time should be able to drag,but should remain in the same position as well
            //breaktime scenario 1: breakTime cant be added as a first competition of a stage
            if (item.code === "Break Time" && destination.index === 0) return;

            // If moving within the same column
            if (sourceColumnKey === destinationColumnKey) {
                //breaktime scenario 2: if break time is moved within the first column do nothing
                if (destinationColumnKey === "Events_Events") {
                    return
                }
                const newColumnItems = [...tableData[sourceColumnKey]];
                const startTime = tableData[sourceColumnKey][0]?.startTime

                newColumnItems.splice(source.index, 1);
                newColumnItems.splice(destination.index, 0, item);
                const timeAlteredCol = setTimingsWithinCol(newColumnItems, startTime)
                setTableData(prevData => ({
                    ...prevData,
                    [sourceColumnKey]: timeAlteredCol
                }));
            } else { // If moving to a different column
                const sourceColumnItems = [...tableData[sourceColumnKey]];
                const destinationColumnItems = [...tableData[destinationColumnKey]];

                //breaktime scenario 3: if break time is moved from first column, only add it to the destination but dont remove from source
                if (item.code === "Break Time" && sourceColumnKey === "Events_Events") {
                    destinationColumnItems.splice(destination.index, 0, { ...item, competitionId: uuidv4() });
                    setTableData(prevData => ({
                        ...prevData,
                        [sourceColumnKey]: sourceColumnItems,
                        [destinationColumnKey]: destinationColumnItems
                    }));
                } else if (item.code === "Break Time" && destinationColumnKey === "Events_Events") {
                    const startTime = tableData[sourceColumnKey][0]?.startTime
                    sourceColumnItems.splice(source.index, 1);//breaktime scenario 3:if added to ist col from other cols, just remove from source
                    const timeAlteredCol = setTimingsWithinCol(sourceColumnItems, startTime)

                    setTableData(prevData => ({
                        ...prevData,
                        [sourceColumnKey]: timeAlteredCol,
                        [destinationColumnKey]: destinationColumnItems
                    }));
                } else {//all scenarios except breakTime
                    const startTimeForSource = tableData[sourceColumnKey][0]?.startTime
                    const startTimeForDest = tableData[destinationColumnKey][0]?.startTime
                    sourceColumnItems.splice(source.index, 1);
                    destinationColumnItems.splice(destination.index, 0, item);
                    const timeAlteredSource = sourceColumnKey !== "Events_Events" ? setTimingsWithinCol(sourceColumnItems, startTimeForSource) : sourceColumnItems
                    const timeAlteredDest = destinationColumnKey !== "Events_Events" ? setTimingsWithinCol(destinationColumnItems, startTimeForDest) : destinationColumnItems
                    const updatedTableData = {
                        ...tableData,
                        [sourceColumnKey]: timeAlteredSource,
                        [destinationColumnKey]: timeAlteredDest
                    };
                    evokeFilteredComp(updatedTableData)
                    setTableData(prevData => ({
                        ...prevData,
                        [sourceColumnKey]: timeAlteredSource,
                        [destinationColumnKey]: timeAlteredDest
                    }));
                }
                if (destinationColumnItems.length === 1) {
                    const duration = calculateCompDuration(item)
                    const start = "8:00 AM"
                    const end = moment(start, 'hh:mm A').add(duration, 'minutes').format('hh:mm A');
                    setStartTime(start)
                    setEndTime(end)
                    destinationColumnItems[0].startTime = start
                    destinationColumnItems[0].endTime = end
                    setTableData(prevData => ({
                        ...prevData,
                        [destinationColumnKey]: destinationColumnItems
                    }));
                    setColumnKey(destinationColumnKey)
                    openOrCloseModal(dispatch, "timeSetter", eventId);
                }
                if (item.code === "Break Time" && destinationColumnKey !== "Events_Events") {
                    const prevItem = tableData[destinationColumnKey][destination.index - 1];
                    let endMoment = moment(item?.endTime, 'h:mm A');
                    const startTime = moment(prevItem.endTime, 'hh:mm A');
                    const diff = sourceColumnKey == "Events_Events" ? 30 : endMoment.diff(startTime, 'minutes')
                    const endTime = moment(prevItem.endTime, 'hh:mm A').add(diff, 'minutes');
                    const start = startTime.format('hh:mm A')
                    const end = endTime.format('hh:mm A')
                    setStartTime(start)
                    setEndTime(end)
                    destinationColumnItems[destination.index].startTime = start
                    destinationColumnItems[destination.index].endTime = end
                    setTableData(prevData => ({
                        ...prevData,
                        [destinationColumnKey]: destinationColumnItems
                    }));
                    breakTimeHandler(destinationColumnKey)
                }
            }
        } catch (err) {
            console.log(err)
        }
    };

    const removeStageModalHandler = (columnIndex, columnName, columnId) => {
        try {
            setDeletableIndex(columnIndex)
            setDeletableName(columnName)
            setDeleteableStageId(columnId)
            openOrCloseModal(dispatch, "deleteStage", eventId);
        } catch (err) {
            console.log(err)
        }
    }

    const removeStage = async () => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/schedule-maker/delete-stage/${deleteableStageId}`
            await serviceConsumer("DELETE", url)
            const stageRemovedTableData = removeStageHandler(deletableIndex, tableData, evokeFilteredComp)
            setTableData(stageRemovedTableData)
            closeDeleteModal()
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    const closeDeleteModal = () => {
        openOrCloseModal(dispatch, "", "");
        setIsBreakTime(false)
        setIsUpdate(false)
    }

    const updateTimeHandler = (comp, columnKey) => {
        try {
            setStartTime(comp.startTime)
            setEndTime(comp.endTime)
            setDraggedCompetition(comp)
            setColumnKey(columnKey)
            setIsUpdate(true)
            openOrCloseModal(dispatch, "timeSetter", eventId);
        }
        catch (err) {
            console.log(err)
        }
    }

    const breakTimeHandler = (columnKey) => {
        try {
            if (columnKey !== "Events_Events") {
                setColumnKey(columnKey)
                setIsBreakTime(true)
                openOrCloseModal(dispatch, "timeSetter", eventId);
            }
        } catch (err) {
            console.log(err)
        }
    }

    //styling helpers
    const stickyStyle = { position: "sticky", left: 0, background: "white", zIndex: 1 }
    const nonStickyStyle = {}
    const tableWidth = `${(Object.keys(tableData).length * 250) + 5}px`


    return (
        <div className="stagesTableDiv" style={{ width: tableWidth, maxWidth: "100%", overflowX: "scroll", marginTop: "30px" }}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <table className="table my_table stagesTable ">
                    <tbody>
                        <tr className="header-row stagesTableRow1">
                            {Object.keys(tableData).map((columnKey, columnIndex) => getHeadingTd(columnKey, columnIndex, stickyStyle, nonStickyStyle, removeStageModalHandler))}
                        </tr>

                        <tr>
                            {Object.keys(tableData).map((columnKey, columnIndex) => {
                                return (
                                    <td key={columnKey}
                                        style={columnIndex === 0 ? stickyStyle : nonStickyStyle}>
                                        <Droppable droppableId={columnKey}>
                                            {(provided, snapshot) => (
                                                < div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    onScroll={(e) => syncScroll(e, columnIndex)}
                                                    className={columnIndex === 0 ? "" : `scroll-column`}
                                                >
                                                    {tableData[columnKey].map((item, index) => (
                                                        <>
                                                            {item?.competitionId && < Draggable key={item?.competitionId} draggableId={item?.competitionId} index={index} i >
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className={getDraggableItemClass(columnIndex, snapshot, index, item?.code)}>
                                                                        {(item?.code === "Break Time" && columnIndex !== 0)
                                                                            ? <>
                                                                                <div style={{ borderRight: "1px solid #DCDCDC" }} className="breakTimeDiv">{`- ${item?.code} -`} <div onClick={() => removeCompFromStage(columnKey, item, index, tableData, setTableData, evokeFilteredComp)} className="hoverOverlay">X</div> </div>
                                                                                <div onClick={() => updateTimeHandler(item, columnKey)} className="breakTimeDiv">{item?.startTime}-{item?.endTime}</div>
                                                                            </>
                                                                            : columnIndex === 0
                                                                                ? <> {item?.code === "Break Time" || (index === 0)
                                                                                    ? <> {item?.code} </>
                                                                                    : <>
                                                                                        <div className="eventsCompClass">{item?.code} &nbsp;</div>
                                                                                        <div className="eventsCompCount">{item?.dancers?.length}</div>
                                                                                    </>}
                                                                                </>
                                                                                : <>
                                                                                    <div className="otherColumnCompName" >{item?.code} &nbsp; <div onClick={() => removeCompFromStage(columnKey, item, index, tableData, setTableData, evokeFilteredComp)} className="hoverOverlay">X</div></div>
                                                                                    <div className="otherColumnCompCount">{item?.dancers?.length}</div>
                                                                                    {item?.startTime !== "Invalid date" && <div className="otherColumnTime" onClick={() => updateTimeHandler(item, columnKey)}>{item?.startTime}-{item?.endTime}</div>}
                                                                                </>}
                                                                    </div>
                                                                )}
                                                            </Draggable >}
                                                        </>
                                                    ))
                                                    }
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable >
                                    </td>
                                );

                            })}
                        </tr>
                    </tbody>
                </table>
            </DragDropContext >
            {
                modal.modal === "timeSetter" &&
                <TimeSetterModal
                    modalState={true}
                    competition={draggedCompetition}
                    tableData={tableData}
                    setTableData={setTableData}
                    isBreakTime={isBreakTime}
                    setIsBreakTime={setIsBreakTime}
                    setIsUpdate={setIsUpdate}
                    isUpdate={isUpdate}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    columnKey={columnKeyy}
                />
            }
            {
                modal.modal === "deleteStage" &&
                <ConfirmDeleteModal modalState={true} schedule={true} stageName={deletableName} cancel={closeDeleteModal} confirm={removeStage} dancerPage={true} />
            }
        </div >

    );
};

export default StagesTable;



